<template lang="pug">
.manage-filters

  .d-flex(v-if="!show_form")
    v-spacer
    v-badge(
      :value="is_dirty"
      color="error"
      offset-x="18"
      bordered overlap
    )
      v-btn(
        @click="show_form = true"
        color="secondary"
        x-large depressed rounded
      )
        v-icon(left) mdi-filter-variant
        | Filtre

  v-card.card-simple.rounded-xl(v-else)
    v-btn.close-form(
      @click="show_form = false"
      icon large depressed rounded
    )
      v-icon(color="secondary") mdi-close
    
    .text-h5.font-weight-bold.primary--text.pa-8.pb-0 Legg til filtre
    v-card-text.pa-8
      form-filters(
        v-model="filters_proxy"
        :locked-filters="locked_filters"
      )

      v-btn.mt-8(
        :disabled="!is_dirty"
        @click="submit"
        color="secondary"
        x-large block depressed rounded
      ) Lagre
</template>

<script>
import { get, isEqual } from 'lodash'
import { mapState, mapMutations } from 'vuex'
import { FormFilters } from '@/components/form'

export default {
  name: 'ManageFilters',
  components: { FormFilters },
  data: () => ({
    show_form: true,

    filters_proxy: {}
  }),
  computed: {
    ...mapState('Company', ['company']),
    ...mapState('Search', ['filters']),
    locked_filters() {
      return get(this.company, 'locked_filters', {})
    },
    is_dirty() {
      return !isEqual(this.filters, this.filters_proxy)
    }
  },
  methods: {
    ...mapMutations('Search', ['SEARCH_SET_FILTERS']),
    submit() {
      this.SEARCH_SET_FILTERS(this.filters_proxy)
      this.show_form = false
    }
  },
  mounted() {
    this.SEARCH_SET_FILTERS(this.filters_proxy)
  }
}
</script>

<style lang="stylus" scoped>
.manage-filters

  .close-form
    top 26px
    right 26px
    position absolute
</style>
