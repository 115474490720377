<template lang="pug">
v-card.card-simple.rounded-xl.data-card
  .map(ref="map")
  
  data-card-buttons(
    :number="nrop_phone"
    :next-button="nextButton"
    :hide-buttons="hideButtons"
  )

  v-card-text.pa-8
    //- Title
    .text-h5.font-weight-bold.primary--text.pa-0.pb-4(v-if="title") {{ title }}

    v-list.pa-0(color="transparent")

      //- Phone
      data-card-line(
        :content="nrop_phone"
        icon="mdi-dialpad"
        filter-name="nummer"
      )

      //- Reservert
      data-card-line(
        :content="rreg_title"
        :icon="rreg_isReserved ? 'mdi-phone-off' : 'mdi-phone-check'"
        :icon-color="rreg_isReserved ? 'red' : 'green'"
        filter-name="reservert"
      )

      //- Address
      data-card-line(
        :content="nrop_address"
        icon="mdi-map-marker"
        icon-color="blue"
        filter-name="adresse"
      )

      //- Age
      data-card-line(
        :content="freg_age"
        icon="mdi-human-cane"
        filter-name="alder"
      )

      //- Gender
      data-card-line(
        :content="freg_gender"
        :icon="freg_gender === 'Mann' ? 'mdi-gender-male' : 'mdi-gender-female'"
        filter-name="kjønn"
      )

      //- Housing
      data-card-line(
        :content="freg_housing"
        icon="mdi-home-city"
        filter-name="bruksenhet"
      )

      //- Moving date
      data-card-line(
        :content="freg_movingDate"
        icon="mdi-truck"
        filter-name="flyttedato"
      )

      //- Citizenship
      data-card-line(
        :content="freg_citizenship"
        icon="mdi-passport"
        filter-name="statsborgerskap"
      )

      //- Birth country
      data-card-line(
        :content="freg_birthCountry"
        icon="mdi-baby"
        filter-name="fødeland"
      )

      //- Civil status
      data-card-line(
        :content="freg_civilStatus"
        icon="mdi-ring"
        filter-name="sivilstatus"
      )

      //- Units
      data-card-line(
        :content="freg_units"
        icon="mdi-circle-double"
        filter-name="krets"
      )

    .d-flex.mt-4(v-if="nrop_timestamp")
      v-spacer
      pre(style="font-size:10px;") Prosessert {{ nrop_timestamp }}

  //- Car data
  v-card-text.car-data.pa-8(v-if="show_svv")

    v-list.pa-0(color="transparent")

      //- Brand
      data-card-line(
        :content="svv_car_brand"
        icon="mdi-car"
        icon-color="secondary"
        filter-name="bilmerke"
      )

      //- Fuel code
      data-card-line(
        :content="svv_car_fuelCode"
        icon="mdi-gas-station"
        icon-color="secondary"
        filter-name="drivstoff"
      )

    .d-flex.mt-4(v-if="svv_timestamp")
      v-spacer
      pre(style="font-size:10px;") Prosessert {{ svv_timestamp }}

  //- Log
  v-card-text.log-data.pa-8(v-if="logs.length > 0")
    .text-h5.font-weight-bold.primary--text.pa-0.pb-4 Logg
    v-simple-table(dense)
      template(#default)
        thead
          tr
            th Dato
            th Resultat
        tbody
          tr(
            v-for="(log) in logs"
            :key="`log-${log.log_id}`"
          )
            td {{ formatLogData(log).date }}
            td {{ formatLogData(log).text }}
</template>

<script>
import mapboxgl from 'mapbox-gl'
import { get } from 'lodash'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { default as DataCardButtons } from './DataCardButtons'
import { default as DataCardLine } from './DataCardLine'
import { EventBus, Geocoder } from '@/service'

dayjs.extend(duration)

const ITEMS_LOG_TYPE = [
  { text: 'Ikke svar', value: 'NO_ANSWER' },
  { text: 'Avvist', value: 'REJECTED' },
  { text: 'Tilbud', value: 'OFFER' },
  { text: 'Ukjent', value: null }
]

const ITEMS_HOUSING_TYPE = [
  { text: 'Bolig', value: 'bolig' },
  { text: 'Annet enn bolig', value: 'annetEnnBolig' },
  { text: 'Fritidsbolig', value: 'fritidsbolig' },
  { text: 'Ikke godkjent bolig', value: 'ikkeGodkjentBolig' },
  { text: 'Unummerert bruksenhet', value: 'unummerertBruksenhet' }
]
const ITEMS_CIVIL_STATUS = [
  { text: 'Uoppgitt', value: 'uoppgitt' },
  { text: 'Ugift', value: 'ugift' },
  { text: 'Gift', value: 'gift' },
  { text: 'Enke eller enkemann', value: 'enkeEllerEnkemann' },
  { text: 'Skilt', value: 'skilt' },
  { text: 'Registrert partner', value: 'registrertPartner' },
  { text: 'Separert partner', value: 'separertPartner' },
  { text: 'Skilt partner', value: 'skiltPartner' },
  { text: 'Gjenlevende partner', value: 'gjenlevendePartner' }
]
const ITEMS_SVV_FUEL_CODE = [
  { text: 'Bensin', value: 1 },
  { text: 'Diesel', value: 2 },
  { text: 'Parafin', value: 3 },
  { text: 'Gass', value: 4 },
  { text: 'Elektrisk', value: 5 },
  { text: 'Hydrogen', value: 6 },
  { text: 'Biodiesel', value: 10 },
  { text: 'Biobensin', value: 11 },
  { text: 'LPG-gass', value: 12 },
  { text: 'CNG-gass', value: 13 },
  { text: 'Metanol', value: 14 },
  { text: 'Etanol', value: 15 },
  { text: 'Komprimert luft', value: 20 },
  { text: 'Annet drivstoff', value: 9 }
]

export default {
  name: 'DataCard',
  props: ['data', 'nextButton', 'hideButtons', 'number'],
  components: {
    DataCardButtons,
    DataCardLine
  },
  data: () => ({
    logs: [],
    map: null,
    marker: null
  }),
  computed: {
    title() {
      const firstName = get(this.data, 'nrop.firstName', null)
      const middleName = get(this.data, 'nrop.middleName', null)
      const lastName = get(this.data, 'nrop.lastName', null)
      const companyName = get(this.data, 'nrop.companyName', null)

      if (firstName || middleName || lastName) {
        return `${firstName ? `${firstName} ` : ''}${
          middleName ? `${middleName} ` : ''
        }${lastName ? `${lastName} ` : ''}`
      } else if (companyName) {
        return companyName
      } else {
        return null
      }
    },
    nrop_phone() {
      return get(this.data, 'nrop.phone', null)
    },
    rreg_isReserved() {
      return get(this.data, 'rreg.types', []).length > 0
    },
    rreg_title() {
      const types = get(this.data, 'rreg.types', [])

      const description =
        types.length > 0
          ? 'Telefonnummeret er reservert for telefonsalg'
          : 'Telefonnummeret er ikke reservert for telefonsalg'

      return `<div class="d-inline-block float-left mt-1">${description}</div>${types
        .map((t) => `<div class="inline-chip"><p>${t}</p>Ja</div>`)
        .join('')}`
    },
    nrop_address() {
      let address = get(this.data, 'nrop.address', null)
      const postalCode = get(this.data, 'nrop.postalCode', null)
      const city = get(this.data, 'nrop.city', null)

      // Fallback to FREG
      if (address) {
        address = address.trim()
        if (address == '') {
          const addressStreet = get(this.data, 'freg.addressStreet', null)
          const addressNumber = get(this.data, 'freg.addressNumber', null)
          address = `${addressStreet ? `${addressStreet} ` : ''}${
            addressNumber ? addressNumber : ''
          }`
        }
      }

      if (!address && !postalCode && !city) return null

      return `${address ? `${address}, ` : ''}${
        postalCode ? `${postalCode.toString().padStart(4, '0')} ` : ''
      }${city ? `(${city})` : ''}`
    },
    freg_age() {
      const birthDate = get(this.data, 'freg.birthDate', null)
      if (!birthDate) return null

      const date_now = dayjs()
      const date_birth = dayjs(birthDate)

      // Epoch start (something was probably wrong)
      if (dayjs(new Date(0)).isSame(date_birth)) return 'Ukjent'

      return `${date_birth.format('D MMMM YYYY')} (${date_now.diff(
        date_birth,
        'y'
      )} år)`
    },
    freg_gender() {
      const gender = get(this.data, 'freg.gender', null)
      if (!gender) return null

      return gender === 'M' ? 'Mann' : 'Kvinne'
    },
    freg_housing() {
      const _housingType = get(this.data, 'freg.housingType', null)
      const housingNumber = get(this.data, 'freg.housingNumber', null)
      const cadastreNumber = get(this.data, 'freg.cadastreNumber', null)

      if (!_housingType && !housingNumber && !cadastreNumber) return null

      const index = ITEMS_HOUSING_TYPE.findIndex(
        ({ value }) => value === _housingType
      )
      const housingType = index < 0 ? '' : `${ITEMS_HOUSING_TYPE[index].text} `

      return `<div class="d-inline-block float-left mt-1">${housingType}</div>${
        housingNumber
          ? `<div class="inline-chip"><p>Husnummer</p>${housingNumber}</div>`
          : ''
      }${
        cadastreNumber
          ? `<div class="inline-chip"><p>Matrikkelnummer</p>${cadastreNumber}</div>`
          : ''
      }`
    },
    freg_movingDate() {
      const movingDate = get(this.data, 'freg.movingDate', null)
      if (!movingDate) return null

      const date_now = dayjs()
      const date_moving = dayjs(movingDate)

      // Epoch start (something was probably wrong)
      if (dayjs(new Date(0)).isSame(date_moving)) return 'Ukjent'

      return `${date_moving.format('D MMMM YYYY')} (${date_now.diff(
        date_moving,
        'y'
      )} år siden)`
    },
    freg_citizenship() {
      const citizenship = get(this.data, 'freg.citizenship', null)
      return citizenship ? citizenship : null
    },
    freg_birthCountry() {
      const birthCountry = get(this.data, 'freg.birthCountry', null)
      return birthCountry ? birthCountry : null
    },
    freg_civilStatus() {
      const _civilStatus = get(this.data, 'freg.civilStatus', null)
      if (!_civilStatus) return null

      const index = ITEMS_CIVIL_STATUS.findIndex(
        ({ value }) => value === _civilStatus
      )
      return index < 0 ? null : `${ITEMS_CIVIL_STATUS[index].text} `
    },
    freg_units() {
      const basicUnit = get(this.data, 'freg.basicUnit', null)
      const votingUnit = get(this.data, 'freg.votingUnit', null)
      const schoolUnit = get(this.data, 'freg.schoolUnit', null)
      const churchUnit = get(this.data, 'freg.churchUnit', null)

      return `${
        basicUnit
          ? `<div class="inline-chip"><p>Grunnkrets</p>${basicUnit}</div>`
          : ''
      }${
        votingUnit
          ? `<div class="inline-chip"><p>Stemmekrets</p>${votingUnit}</div>`
          : ''
      }${
        schoolUnit
          ? `<div class="inline-chip"><p>Skolekrets</p>${schoolUnit}</div>`
          : ''
      }${
        churchUnit
          ? `<div class="inline-chip"><p>Kirkekrets</p>${schoolUnit}</div>`
          : ''
      }`
    },
    nrop_timestamp() {
      const timestamp = get(this.data, 'nrop.timestamp', null)
      if (!timestamp) return null

      return dayjs(timestamp).format('D MMM HH:mm, YYYY')
    },
    show_svv() {
      return this.svv_car_brand || this.svv_car_fuelCode
    },
    svv_car_brand() {
      let brandName = get(this.data, 'svv.car.brandName', null)
      if (brandName) {
        const brandModel = get(this.data, 'svv.car.brandModel', null)
        brandName += brandModel ? ` (${brandModel})` : ''
      }

      return brandName
    },
    svv_car_fuelCode() {
      const _fuelCode = get(this.data, 'svv.car.fuelCode', null)
      if (!_fuelCode) return null

      const index = ITEMS_SVV_FUEL_CODE.findIndex(
        ({ value }) => value === _fuelCode
      )
      return index < 0 ? null : `${ITEMS_SVV_FUEL_CODE[index].text} `
    },
    svv_timestamp() {
      const timestamp = get(this.data, 'svv.timestamp', null)
      if (!timestamp) return null

      return dayjs(timestamp).format('D MMM HH:mm, YYYY')
    }
  },
  watch: {
    nrop_address: {
      immediate: true,
      handler() {
        this.geocode()
      }
    }
  },
  methods: {
    ...mapActions('Log', ['listLog']),
    async doGetLogs() {
      try {
        const logs = await this.listLog(this.nrop_phone || this.number)
        this.logs = logs.sort((a, b) => b.created_at - a.created_at)
      } catch (e) {
        // NOP
        console.log(e)
      }
    },
    handleNext() {
      // Let log submit process before fetching logs here
      if (this.hideButtons) setTimeout(this.doGetLogs, 1000)
    },
    geocode() {
      this.$nextTick(async () => {
        if (!this.nrop_address) return

        try {
          const response = await Geocoder.geocode(this.nrop_address)
          const center = get(response, 'features[0].center', null)
          this.map.setCenter(center)

          if (this.marker) this.marker.remove()

          this.marker = new mapboxgl.Marker({ color: '#2196F3' })
            .setLngLat(center)
            .addTo(this.map)
        } catch (e) {
          this.map.setCenter([10.745644, 59.929979])
        }
      })
    },
    formatLogData(log) {
      const type = ITEMS_LOG_TYPE.find(
        ({ value }) => value === get(log, 'type', null)
      )
      return {
        date: dayjs(get(log, 'created_at', null)).format('D MMM HH:mm, YYYY'),
        text: get(type, 'text', 'Ukjent')
      }
    }
  },
  async mounted() {
    this.doGetLogs()
    EventBus.$on('data-card-buttons:submit', this.handleNext)

    this.map = new mapboxgl.Map({
      container: this.$refs.map,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 16,
      projection: 'globe',
      attributionControl: false,
      logoPosition: 'bottom-right'
    })
  },
  beforeDestroy() {
    EventBus.$off('data-card-buttons:submit', this.handleNext)

    if (this.map) this.map.remove()
    if (this.marker) this.marker.remove()
  }
}
</script>

<style lang="stylus" scoped>
.v-card
  .map
    width 100%
    height 200px
    position relative

  .v-card__text
    &.car-data
      background #EBE8FC
      color var(--v-secondary-base) !important

      >>> .v-list-item__title
        color var(--v-secondary-base) !important

    &.log-data
      background #fef9e8

      .v-data-table
        background transparent
        color var(--v-primary-base) !important

        th
          color var(--v-primary-base) !important

        tbody
          tr:hover
            background #f6f0db !important
</style>
