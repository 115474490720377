<template lang="pug">
.search
  .text-h5.font-weight-bold.primary--text.mb-6 Søk

  v-tabs.mb-8(
    background-color="transparent"
    color="secondary"
  )
    v-tab(
      to="/search"
      exact
    ) Reservert søk
    v-tab(
      v-if="false"
      to="/search/filtrert"
      exact
    ) Filtrert søk

  router-view
</template>

<script>
export default {
  name: 'Search'
}
</script>

<style lang="stylus" scoped>
.search
  .v-tabs
    position relative

    >>> .v-slide-group__content
      &::after
        content ''
        height 2px
        background rgba(0, 0, 0, .2)
        display block
        position absolute
        left 0
        right 0
        bottom 0
</style>
