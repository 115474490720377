<template lang="pug">
v-card-actions.pa-8.pb-0(v-if="!hidden")
  v-row
    v-col(
      :md="nextButton ? 3 : 4"
      cols="12"
    )
      v-btn(
        @click="doCreateLog('NO_ANSWER')"
        color="error"
        x-large block depressed rounded
      )
        v-icon(left) mdi-phone-missed
        | Ikke svar
    v-col(
      :md="nextButton ? 3 : 4"
      cols="12"
    )
      v-btn(
        @click="doCreateLog('REJECTED')"
        color="secondary"
        x-large block depressed rounded
      )
        v-icon(left) mdi-hand-back-left
        | Avvist
    v-col(
      :md="nextButton ? 3 : 4"
      cols="12"
    )
      v-btn(
        @click="doCreateLog('OFFER')"
        color="success"
        x-large block depressed rounded
      )
        v-icon(left) mdi-hand-heart
        | Tilbud
    v-col(
      v-if="nextButton"
      cols="12"
      md="3"
    )
      v-btn(
        @click="submit"
        color="primary"
        x-large block depressed rounded
      )
        | Hopp over
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/service'

export default {
  name: 'DataCardButtons',
  props: {
    number: {
      type: String
    },
    nextButton: {
      type: Boolean,
      default: false
    },
    hideButtons: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hidden: false
  }),
  methods: {
    ...mapActions('Log', ['createLog']),
    submit() {
      EventBus.$emit(`data-card-buttons:submit`)
    },
    async doCreateLog(type) {
      if (this.hideButtons) this.hidden = true
      this.submit()

      try {
        await this.createLog({
          type,
          number: this.number,
          data: null
        })
      } catch (e) {
        // NOP
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
