<template lang="pug">
.filtrert-session.pb-8(:class="{ 'mt-16': upcoming.length > 0 }")
  .filtrert-session-stack(
    :class="`upcoming-length-${upcoming.length}`"
    ref="stack"
  )
    template(v-if="upcoming.length <= 0")
      v-skeleton-loader.rounded-xl(
        v-if="loading"
        type="image"
      )
      v-card.card-simple.rounded-xl(v-else)
        v-list(color="transparent")
          v-list-item(color="secondary")
            v-list-item-avatar.mr-3.my-0
              v-icon(
                color="warning"
                large
              ) mdi-cards
            v-list-item-content.py-0
              v-list-item-title Ingen flere resultater
              v-list-item-subtitle Prøv å bytt filtre.
</template>

<script>
import Vue from 'vue'
import { get, isEqual } from 'lodash'
import { mapState, mapActions } from 'vuex'
import store from '@/store'
import vuetify from '@/lib/vuetify'
import { EventBus } from '@/service'
import { default as DataCard } from './DataCard'

const DataCardClass = Vue.extend(DataCard)
const BATCH_TRIGGER_LENGTH = 5

export default {
  name: 'FiltrertSession',
  data: () => ({
    loading: false,
    offset: 0,
    batch: [],
    backlog: [],
    metadata: null
  }),
  computed: {
    ...mapState('Search', ['filters']),
    upcoming() {
      if (!this.batch) return []
      return this.batch.length > 0 ? this.batch.slice(0, 3) : []
    }
  },
  watch: {
    filters: {
      immediate: true,
      handler() {
        this.offset = 0
        this.batch = []
        // Don't clear backlog
        this.metadata = null
        this.fetch()
      }
    },
    upcoming: {
      immediate: true,
      handler(v) {
        if (!v || !this.$refs.stack) return

        let children = get(this.$refs, 'stack.children', [])

        const ids = Array.from(children).map(({ id }) => id)
        for (const card of v) {
          const id = `card-${card.nrop.phone}`
          if (ids.includes(id)) continue

          // New card, instantiate component and add to container
          const instance = new DataCardClass({
            propsData: {
              data: card,
              nextButton: true,
              hideButtons: false
            },
            store,
            vuetify
          }).$mount()
          instance.$el.id = id
          this.$refs.stack.prepend(instance.$el)

          // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search
          this.$gtag.event('search', {
            search_term: get(card, 'nrop.phone', null)
          })
        }

        // Let animations happen and remove excess
        setTimeout(() => {
          children = get(this.$refs, 'stack.children', [])
          const remove = Array.from(children)
            .map(({ id }) => id)
            .filter((id) => id)
            .slice(3)
          remove.forEach((id) => {
            document.getElementById(id).remove()
          })
        }, 500)
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Search', ['filtrert']),
    next() {
      if (this.upcoming.length <= 0) return

      // Move from start of batch (shift) to en of backlog (unshift)
      if (this.batch.length > 0) this.backlog.unshift(this.batch.shift())

      if (this.batch.length <= BATCH_TRIGGER_LENGTH) this.fetch()
    },
    async fetch() {
      if (isEqual(this.filters, {}) || this.loading) return

      this.loading = true
      try {
        const { data, metadata } = await this.filtrert({
          offset: this.offset,
          filters: this.filters
        })
        this.offset += data.length || 0
        this.metadata = metadata
        this.batch.push(...data)
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    EventBus.$on('data-card-buttons:submit', this.next)
  },
  beforeDestroy() {
    EventBus.$off('data-card-buttons:submit', this.next)
  }
}
</script>

<style lang="stylus" scoped>
@keyframes fade
  0%
    opacity 0
  100%
    opacity 1

@keyframes fall
  0%
    opacity 1
    transform perspective(4400px) rotateX(0deg) translate3d(0px, 0px, 0px)
  100%
    opacity 0
    transform perspective(4400px) rotateX(-45deg) translate3d(0px, 10px, 0px)

.filtrert-session
  position relative

  .filtrert-session-stack
    position relative
    display flex
    justify-content center

    >>> .v-skeleton-loader
      width 100%

    >>> .v-card
      width 100%

    >>> .data-card
      transition all 100ms ease-in-out
      display none

      animation fade 2000ms ease-out forwards 1
      -webkit-animation fade 2000ms ease-out forwards 1

    &.upcoming-length-3 >>> .data-card:nth-child(1)
      max-height 56px
      top -36px
      width 80%
      position absolute
      display block

    &.upcoming-length-3 >>> .data-card:nth-child(2), &.upcoming-length-2 >>> .data-card:nth-child(1)
      max-height 38px
      top -18px
      width 90%
      position absolute
      display block

    &.upcoming-length-3 >>> .data-card:nth-child(3), &.upcoming-length-2 >>> .data-card:nth-child(2), &.upcoming-length-1 >>> .data-card:nth-child(1)
      top 0px
      width 100%
      display block
      box-shadow rgba(0, 0, 0, .08) 0px -5px 6px, rgba(0, 0, 0, 0.05) 0px 1px 2px !important

    &.upcoming-length-3 >>> .data-card:nth-child(4), &.upcoming-length-2 >>> .data-card:nth-child(3), &.upcoming-length-1 >>> .data-card:nth-child(2), &.upcoming-length-0 >>> .data-card:nth-child(1)
      top 0px
      width 100%
      position absolute
      display block
      transform-origin center bottom
      animation fall 250ms ease-out forwards 1
      -webkit-animation fall 250ms ease-out forwards 1
</style>
