<template lang="pug">
.reservert-search
  v-text-field.mb-2(
    :disabled="!enabled_reservert || loading"
    @keyup.enter="search"
    @click:append="search"
    v-model="number"
    ref="number"
    type="tel"
    placeholder="Søk etter nummer"
    append-icon="mdi-magnify"
    rounded filled autofocus
  )

  template(v-if="!enabled_reservert")
    v-card.card-simple.rounded-xl
      v-list(color="transparent")
        v-list-item(color="secondary")
          v-list-item-avatar.mr-3.my-0
            v-icon(
              color="warning"
              large
            ) mdi-package-variant-closed-remove
          v-list-item-content.py-0
            v-list-item-title Du mangler abonnement
            v-list-item-subtitle For å kunne søke i reservasjonsregisteret vennligst opprett et abonnement.
          v-list-item-action.ma-0
            v-tooltip(
              transition="drop-front"
              color="black"
              bottom
            )
              span Gå til abonnement
              template(#activator="{ on, attrs }")
                v-btn(
                  v-bind="attrs"
                  v-on="on"
                  to="/billing/subscription"
                  icon
                )
                  v-icon mdi-arrow-right-thin

  template(v-if="loading")
    v-skeleton-loader.mt-2(
      type="paragraph"
    )

  template(v-if="!valid")
    v-card.status.card-simple.rounded-xl
      v-card-title
        v-icon(color="primary" left) mdi-progress-question
        .primary--text Ugyldig nummer

  template(v-if="valid && result")
    data-card(
      v-if="result.nrop"
      :data="result"
      :hide-buttons="true"
      :number="number"
    )
    v-card.status.card-simple.rounded-xl(v-else)
      v-card-title
        v-icon(color="error" left) mdi-progress-alert
        .error--text Vi fant dessverre ikke nummeret. Dette nummeret kan være reservert!

  template(v-if="enabled_reservert && valid && !result && !loading")
    .d-flex.justify-center
      v-img.mt-12(
        :src="require('../../assets/img/undraw/undraw_file_searching_re_3evy.svg')"
        max-width="250"
      )
</template>

<script>
import { get, debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { default as DataCard } from './DataCard'

// Norwegian phone number
const REGEX = /^(0047|\+47|47)?[2-9]\d{7}$/

export default {
  name: 'ReservertSearch',
  components: {
    DataCard
  },
  data: () => ({
    loading: false,
    preventSearch: false,
    valid: true,
    number: '',
    result: null
  }),
  computed: {
    ...mapState('Company', ['company']),
    enabled_reservert() {
      return get(this.company, 'stripe_feature_object.enabled_reservert', false)
    }
  },
  watch: {
    number(v) {
      this.triggerSearch(v)
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Search', ['reservert']),
    triggerSearch: debounce(function () {
      if (this.preventSearch) {
        this.preventSearch = false
        return
      }

      this.search()
    }, 250),
    async search() {
      if (!this.number) return

      // Remove all whitespaces
      const number = this.number.replace(/\s/g, '')

      // Check if number is valid
      this.valid = REGEX.test(number)
      if (!this.valid) return

      this.preventSearch = true
      this.result = null
      this.loading = true
      try {
        this.result = await this.reservert(number)
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search
        this.$gtag.event('search', {
          search_term: number
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.preventSearch = false
        this.loading = false
        this.$nextTick(() => this.$refs.number.focus())
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.reservert-search
  .v-card
    &.status
      border-radius 50px !important
</style>
