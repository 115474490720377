<template lang="pug">
v-list-item.px-0(v-if="content")
  v-list-item-avatar.mr-2.my-0
    v-tooltip(
      transition="drop-front"
      color="black"
      bottom
    )
      span {{ icon_text }}
      template(#activator="{ on, attrs }")
        v-icon(
          v-bind="attrs"
          v-on="on"
          :color="iconColor || 'primary'"
        ) {{ icon }}
  v-list-item-content.py-0
    v-list-item-title(v-html="content")
  v-list-item-action.ma-0
    v-tooltip(
      transition="drop-front"
      color="black"
      bottom
    )
      span {{ copy_text }}
      template(#activator="{ on, attrs }")
        v-btn(
          v-bind="attrs"
          v-on="on"
          @click="copyToClipboard"
          icon
        )
          v-icon mdi-content-copy
</template>

<script>
export default {
  name: 'DataCardLine',
  props: ['icon', 'iconColor', 'filterName', 'content'],
  computed: {
    icon_text() {
      const name = this.filterName
      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    copy_text() {
      return `Kopier ${this.filterName}`
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.content.replace(/<[^>]*>?/gm, ' '))
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-list-item
  >>> .inline-chip
    margin-left 15px
    padding-right 12px
    font-size 14px
    font-weight bold
    background #f8f8fb
    display inline-block
    border-radius 15px
    overflow hidden
    float left

    p
      margin 0 8px 0 0
      padding 4px 8px 4px 10px
      background #e6e3fa
      color var(--v-secondary-base) !important
      font-weight normal
      display inline-block
</style>
