<template lang="pug">
.search-filtrert
  manage-filters.mb-8

  template(v-if="!enabled_filtrert")
    v-card.card-simple.rounded-xl
      v-list(color="transparent")
        v-list-item(color="secondary")
          v-list-item-avatar.mr-3.my-0
            v-icon(
              color="warning"
              large
            ) mdi-package-variant-closed-remove
          v-list-item-content.py-0
            v-list-item-title Du mangler abonnement
            v-list-item-subtitle For å kunne filtrere i ringelister vennligst opprett et abonnement.
          v-list-item-action.ma-0
            v-tooltip(
              transition="drop-front"
              color="black"
              bottom
            )
              span Gå til abonnement
              template(#activator="{ on, attrs }")
                v-btn(
                  v-bind="attrs"
                  v-on="on"
                  to="/billing/subscription"
                  icon
                )
                  v-icon mdi-arrow-right-thin

  template(v-else)
    filtrert-session
</template>

<script>
import { get } from 'lodash'
import { mapState } from 'vuex'
import { ManageFilters, FiltrertSession } from '@/components/search'

export default {
  name: 'SearchFiltrert',
  components: {
    ManageFilters,
    FiltrertSession
  },
  computed: {
    ...mapState('Company', ['company']),
    enabled_filtrert() {
      return get(this.company, 'stripe_feature_object.enabled_filtrert', false)
    }
  }
}
</script>

<style lang="stylus" scoped></style>
