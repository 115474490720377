<template lang="pug">
.search-reservert
  reservert-search
</template>

<script>
import { ReservertSearch } from '@/components/search'

export default {
  name: 'SearchReservert',
  components: {
    ReservertSearch
  }
}
</script>

<style lang="stylus" scoped></style>
